
const darkMode = window.matchMedia && window.matchMedia('(prefers-color-scheme: dark)').matches;

let isNumber = false, isFraction = false, msg = "";
let totalUnits = 0, bet = 0, vig = 0, totalBuyCost = 0;
let passLinePymnt = 0, sixEightPymnt = 0, fiveNinePymnt = 0, fourTenPymnt = 0;
let correctBetPymnt = 0, isImproper = false, improperAmount = 0, maxOddsPymnt = 0;
let hardSixEightPymnt = 0, hardFourTenPymnt = 0, highSidePymnt = 0, lowSidePymnt = 0;
let cSidePymnt = 0, eSidePymnt = 0, cAndECrapPymnt = 0, cAndEYoPymnt = 0, cAndETtlPymnt = 0, losingDiff = 0;
let theARoll = 0, theBRoll = 0, theCRoll = 0, theDRoll = 0, theERoll = 0, theFRoll = 0 , theGRoll = 0, theHRoll = 0, theIRoll = 0, theJRoll = 0;
let theKRoll = 0, theLRoll = 0, theMRoll = 0, theNRoll = 0 , theORoll = 0, thePRoll = 0, theQRoll = 0, theRRoll = 0, theSRoll = 0, theTRoll = 0, theURoll = 0;
let amtOneIsFraction = false, amtTwoIsFraction = false;
let anySevenPymnt = 0, anyCrapsPymnt = 0, singleFieldPymnt = 0, doubleFieldPymnt = 0;
let eachHighLowPymnt = 0, eachSevenPymnt = 0, eachElevenPymnt = 0, eachTwelvePymnt = 0, eachAcesPymnt = 0, eachAceDeucePymnt = 0;
let eachHlyHighPymnt = 0, eachHlyLowPymnt = 0, eachTwcHighPymnt = 0, eachTwcLowPymnt = 0;
let lowSideHornPymnt = 0, highSideHornPymnt = 0, hornHighAcesPymnt = 0, hornHighAceDeucePymnt = 0, hornHighYoPymnt = 0, hornHighTwelvePymnt = 0;
let lowSideWorldPymnt = 0, highSideWorldPymnt = 0, highSideHopPymnt = 0, lowSideHopPymnt = 0, hopHardwaysPymnt = 0;
let hopFoursPymnt = 0, hopFivesPymnt = 0, hopSixesPymnt = 0, hopSevensPymnt = 0, hopEightsPymnt = 0, hopNinesPymnt = 0, hopTensPymnt = 0;
let comeBetPymnt = 0, sixEightComeBetPymnt = 0, fiveNineComeBetPymnt = 0, fourTenComeBetPymnt = 0;
let dontPassPymnt = 0, sixEightLayOddsPymnt = 0, fiveNineLayOddsPymnt = 0, fourTenLayOddsPymnt = 0, maxLayOdds = 0;
let dontComePymnt = 0, dcLayOddsSixEightPymnt = 0, dcLayOddsFiveNinePymnt = 0, dcLayOddsFourTenPymnt = 0, maxDcLayOdds = 0;
let overlay_six_eight_pymnt = 0, overlay_five_nine_pymnt = 0, overlay_four_ten_pymnt = 0, totalOverlayCost = 0;

function $<T = HTMLElement>(id: string): T {
    return document.getElementById(id) as T;
}
function numCommas (x: number) {
    var parts = x.toString().split(".");
    parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    return parts.join(".");
}
function validateAmount(amt: number, min: number, max: number, msgMin: string, msgMax: string, msgBox: string): boolean {
    const isFraction = amt % 1 !== 0;
    if (isNaN(amt)) {
      $(msgBox).innerHTML = "Please enter a number.";
      return false;
    } else if (isFraction) {
      $(msgBox).innerHTML = "Please enter a whole number.";
      return false;
    } else if (amt < min) {
      $(msgBox).innerHTML = msgMin;
      return false;
    } else if (amt > max) {
      $(msgBox).innerHTML = msgMax;
      return false;
    }
    return true;
}
function activateBtn (btnSet: string, whichVar: number, rollPicked: number) {
    Array.from(document.getElementsByClassName(btnSet)).forEach(theBtn => {
      theBtn.classList.add('neutral-btn');
      theBtn.classList.remove('active-btn');
    });
    (event.currentTarget as HTMLElement).classList.remove('neutral-btn');
    (event.currentTarget as HTMLElement).classList.add('active-btn');
    switch (whichVar) {
        case 1: theARoll = rollPicked; break;
        case 2: theBRoll = rollPicked; break;
        case 3: theCRoll = rollPicked; break;
        case 4: theDRoll = rollPicked; break;
        case 5: theERoll = rollPicked; break;
        case 6: theFRoll = rollPicked; break;
        case 7: theGRoll = rollPicked; break;
        case 8: theHRoll = rollPicked; break;
        case 9: theIRoll = rollPicked; break;
        case 10: theJRoll = rollPicked; break;
        case 11: theKRoll = rollPicked; break;
        case 12: theLRoll = rollPicked; break;
        case 13: theMRoll = rollPicked; break;
        case 14: theNRoll = rollPicked; break;
        case 15: theORoll = rollPicked; break;
        case 16: thePRoll = rollPicked; break;
        case 17: theQRoll = rollPicked; break;
        case 18: theRRoll = rollPicked; break;
        case 19: theSRoll = rollPicked; break;
        case 20: theTRoll = rollPicked; break;
        case 21: theURoll = rollPicked; break;
        default: return;
    }
}
const calculate = {
    passLine: function (amt: number) {
        if(validateAmount(amt, 5, 5000,
            "The minimum on the Pass Line is $5.",
            "Table max on the Pass Line is $5,000.",
            "pass_line_calc_result"
        )) {
            $("pass_line_calc_result").innerHTML = "A $" + numCommas(amt) + " Pass Line bet wins $" + numCommas(amt) + ".";
        }
    },
    passLineOdds: function (betCode: number, amt: number) {
        switch (betCode) {
            case 68:
                if(validateAmount(amt, 5, 25000,
                    "Minimum Odds on the 6 & 8 are $5.",
                    "Max Odds on the 6 & 8 are $25,000.",
                    "six_eight_calc_result"
                    )) {
                        totalUnits = Math.floor(amt / 5);
                        correctBetPymnt = totalUnits * 6;
                        improperAmount = amt % 5;
                        sixEightPymnt = correctBetPymnt + improperAmount;
                        isImproper = improperAmount !== 0;
                        isImproper ? msg = "Odds on the 6 & 8 should be made in multiples of 5. Anything above a multiple of 5 will get paid even money. This bet will pay $" + numCommas(sixEightPymnt) + "." : msg = "$" + numCommas(amt) + " Odds on the 6 or 8 win $" + numCommas(sixEightPymnt) + ".";
                        $("six_eight_calc_result").innerHTML = msg;
                    }
                break;
            case 59:
                if(validateAmount(amt, 2, 20000,
                    "Minimum Odds on the 5 & 9 are $2.",
                    "Max Odds on the 5 & 9 are $20,000.",
                    "five_nine_calc_result"
                )) {
                        fiveNinePymnt = Math.floor((amt / 2) * 3);
                        isImproper = amt % 2 == 1;
                        isImproper ? msg = "Odds on the 5 & 9 should be even. You lose \$0.50 when you bet uneven Odds. This bet will pay $" + numCommas(fiveNinePymnt) + "." : msg = "$" + numCommas(amt) + " Odds on the 5 or 9 win $" + numCommas(fiveNinePymnt) + ".";
                        $("five_nine_calc_result").innerHTML = msg;
                    }
                break;
            case 410:
                if(validateAmount(amt, 1, 15000,
                    "Minimum Odds on the 4 & 10 are $1.",
                    "Max Odds on the 4 & 10 are $15,000.",
                    "four_ten_calc_result"
                )) {
                    fourTenPymnt = amt * 2;
                    $("four_ten_calc_result").innerHTML = "$" + numCommas(amt) + " Odds on the 4 or 10 win $" + numCommas(fourTenPymnt) + ".";
                    }
         }
    },
    maxOdds: function (amt: number) {
        if(validateAmount(amt, 5, 5000,
            "Table minimun is $5. You need to bet at least $5 on the Pass Line.",
            "Table max on the Pass Line is $5,000.",
            "max_odds_calc_result"
        )) {
            maxOddsPymnt = amt * 7;
            $("max_odds_calc_result").innerHTML = "The total payout for a $" + numCommas(amt) + " Pass Line with Max Odds is $" + numCommas(maxOddsPymnt) + ". The total payment for a Pass Line with Max Odds will always be 7x the Pass Line Bet.";
        }
    },
    placeBet: function (betCode: number, amt: number) {
        switch (betCode) {
            case 68:
                if(validateAmount(amt, 6, 6000,
                    "Minimum Place bet on the 6 & 8 is $6.",
                    "Max Place bet on the 6 & 8 is $6,000.",
                    "place_six_eight_calc_result"
                )) {
                    totalUnits = Math.floor(amt / 6);
                    sixEightPymnt = totalUnits * 7;
                    isImproper = amt % 6 !==0;
                    isImproper ? msg = "You must make Place bets on the 6 & 8 in increments of $6.": msg = "A $" + numCommas(amt) + " Place bet on the 6 or 8 wins $" + numCommas(sixEightPymnt) + ".";
                    $("place_six_eight_calc_result").innerHTML = msg;
                }
                break;
            case 59:
                if(validateAmount(amt, 5, 5000,
                    "Minimum Place bet on the 5 & 9 is $5.",
                    "Max Place bet on the 5 & 9 is $5,000.",
                    "place_five_nine_calc_result"
                )) {
                    totalUnits = Math.floor(amt / 5);
                    fiveNinePymnt = totalUnits * 7;
                    isImproper = amt % 5 !==0;
                    isImproper ? msg = "You must make Place bets on the 5 & 9 in increments of $5.": msg = "A $" + numCommas(amt) + " Place bet on the 5 or 9 wins $" + numCommas(fiveNinePymnt) + ".";
                    $("place_five_nine_calc_result").innerHTML = msg;
                }
                break;
            case 410:
                if(validateAmount(amt, 5, 5000,
                    "Minimum Place bet on the 4 & 10 is $5.",
                    "Max Place bet on the 4 & 10 is $5,000.",
                    "place_four_ten_calc_result"
                )) {
                    totalUnits = Math.floor(amt / 5);
                    fourTenPymnt = totalUnits * 9; 
                    isImproper = amt % 5 !==0;
                    isImproper ? msg = "You must make Place bets on the 4 & 10 in increments of $5.": msg = "A $" + numCommas(amt) + " Place bet on the 4 or 10 wins $" + numCommas(fourTenPymnt) + ".";
                    $("place_four_ten_calc_result").innerHTML = msg;
                }
                break;
        }
    },
    buyBet: function (amt: number) {
        if(validateAmount(amt, 20, 5000,
            "Table minimum to Buy the 4 or 10 is $20.",
            "Max Buy on the 4 or 10 is $5,000.",
            "buy_calc_result"
        )) {
            fourTenPymnt = amt * 2;
            vig = Math.floor(amt / 20);
            totalBuyCost = amt + vig
            $("buy_calc_result").innerHTML = "A $" + numCommas(amt) + " Buy on the 4 or 10 costs $" + numCommas(totalBuyCost) + ". It pays $" + numCommas(fourTenPymnt) + " and requres another $" + vig + " to Buy it again after it wins.";
        }
    },
    theHardways: function (betCode: number, amt: number) {    
        switch (betCode) {
            case 68:
                if (validateAmount(amt, 1, 4000,
                "Minimum bet on the Hard 6 & 8 is $1.",
                "Max bet on the Hard 6 & 8 is $4,000.",
                "hard_six_eight_calc_result"
                )) {
                    hardSixEightPymnt = amt * 9;
                    $("hard_six_eight_calc_result").innerHTML = "A $" + numCommas(amt) + " Hard 6 or 8 wins $" + numCommas(hardSixEightPymnt) + ".";
                }
                break;
            case 410:
                if (validateAmount(amt, 1, 5000,
                "Minimum bet on the Hard 4 & 10 is $1.",
                "Max bet on the Hard 4 & 10 is $5,000.",
                "hard_four_ten_calc_result"
                )) {
                    hardFourTenPymnt = amt * 7;
                    $("hard_four_ten_calc_result").innerHTML = "A $" + numCommas(amt) + " Hard 4 or 10 wins $" + numCommas(hardFourTenPymnt) + ".";
                }
                break;
        }
    },
    straightUp: function (betCode: number, amt: number) {
        switch (betCode) {
            case 212:
                if (validateAmount(amt, 1, 1000,
                "Minimum bet on the high side is $1.",
                "Max bet on the high side is $1,000.",
                "straight_high_side_calc_result"
                )) {
                    highSidePymnt = amt * 30;
                    $("straight_high_side_calc_result").innerHTML = "A $" + numCommas(amt) + " bet on the Aces or Twelve wins $" + numCommas(highSidePymnt) + ".";
                }
                break;
            case 311:
                if (validateAmount(amt, 1, 2000,
                "Minimum bet on the low side is $1.",
                "Max bet on the low side is $2,000.",
                "straight_low_side_calc_result"
                )) {
                    lowSidePymnt = amt * 15;
                    $("straight_low_side_calc_result").innerHTML = "A $" + numCommas(amt) + " bet on the Ace Deuce or Yo wins $" + numCommas(lowSidePymnt) + ".";
                }
                break;
        }
    },
    cAndE: function (betCode: number, amt: number, amtTwo: number) {
        cAndECrapPymnt = amt * 3;
        cAndEYoPymnt = amt * 7;
        cSidePymnt = amt * 7;
        eSidePymnt = amtTwo * 15;
        switch (betCode) {
            case 15:
                if (validateAmount(amt, 2, 4000,
                "Minimum bet on the C & E split is $2.",
                "Max bet on the C & E split is $4,000.",
                "c_and_e_split_calc_result"
                )) {
                    if (theARoll == 0) {
                        $("c_and_e_split_calc_result").innerHTML = "You need to pick what rolled.";
                    } else {
                        theARoll == 11 ? $("c_and_e_split_calc_result").innerHTML = "A $" + numCommas(amt) + " bet on the C & E split wins $" + numCommas(cAndEYoPymnt) + " when the Yo rolls." : $("c_and_e_split_calc_result").innerHTML = "A $" + numCommas(amt) + " bet on the C & E split wins $" + numCommas(cAndECrapPymnt) + " when a Crap rolls.";
                    }
                }
                break;
            case 16:
                amtOneIsFraction = amt % 1 !== 0;
                amtTwoIsFraction = amtTwo % 1 !== 0;
                if (theBRoll == 0) {
                    $("c_and_e_calc_result").innerHTML = "You need to pick what rolled.";
                    return;
                }
                if (isNaN(amt) && isNaN(amtTwo)) {
                    $("c_and_e_calc_result").innerHTML = "Please enter a number.";
                    return;
                }
                if(isNaN(amt)) {
                    if (amtTwo < 1) {
                        $("c_and_e_calc_result").innerHTML = "You must bet at least $1";
                        return;
                    } else if (amtTwo > 2000) {
                        $("c_and_e_calc_result").innerHTML = "Max bet on the E (Eleven) is $2,000.";
                        return;
                    } else if (amtTwoIsFraction) {
                        $("c_and_e_calc_result").innerHTML = "Please enter a whole number.";
                        return;
                    } else if (theBRoll !== 11) {
                        $("c_and_e_calc_result").innerHTML = "Your bet lost.";
                        return;
                    } else {
                        $("c_and_e_calc_result").innerHTML = "A $" + numCommas(amtTwo) + " bet on the E (Eleven) wins $" + numCommas(amtTwo * 15) + ".";
                        return;
                    }
                }
                if(isNaN(amtTwo)) {
                    if (amt < 1) {
                        $("c_and_e_calc_result").innerHTML = "You must bet at least $1";
                        return;
                    } else if (amt > 4000) {
                        $("c_and_e_calc_result").innerHTML = "Max bet on the C (Any Craps) is $4,000.";
                        return;
                    } else if (amtOneIsFraction) {
                        $("c_and_e_calc_result").innerHTML = "Please enter a whole number.";
                        return;
                    } else if (theBRoll == 11) {
                        $("c_and_e_calc_result").innerHTML = "Your bet lost.";
                        return;
                    } else {
                        $("c_and_e_calc_result").innerHTML = "A $" + numCommas(amt) + " bet on the C (Any Craps) wins $" + numCommas(cSidePymnt) + ".";
                        return;
                    }
                }
                if (amt < 1 || amtTwo < 1) {
                    $("c_and_e_calc_result").innerHTML = "You must bet at least $1";
                    return;
                } else if (amtOneIsFraction || amtTwoIsFraction) {
                    $("c_and_e_calc_result").innerHTML = "Please enter a whole number.";
                    return;
                } else if (amt > 4000) {
                    $("c_and_e_calc_result").innerHTML = "Max bet on the C (Any Craps) is $4,000.";
                    return;
                } else if (amtTwo > 2000) {
                    $("c_and_e_calc_result").innerHTML = "Max bet on the E (Eleven) is $2,000.";
                    return;
                }
                if (theBRoll == 11) {
                    cAndETtlPymnt = eSidePymnt - amt;
                    losingDiff = Math.abs(cAndETtlPymnt);
                    if (cAndETtlPymnt < 0) {
                        $("c_and_e_calc_result").innerHTML = "Your $" + numCommas(amtTwo) + " Yo won $" + eSidePymnt +  ", which is not enough to cover your $" + numCommas(amt) + " Crap that lost. You need to throw in $" + losingDiff + " to keep this bet up to win again, or take the Crap down and collect your winnings." ;
                        return;
                    } else if (cAndETtlPymnt == 0) {
                        $("c_and_e_calc_result").innerHTML = "Your bet is a push. It is up to win again.";
                        return;
                    } else {
                        $("c_and_e_calc_result").innerHTML = "A $" + numCommas(amt) + "C - $" + numCommas(amtTwo) +  "E wins $" + numCommas(cAndETtlPymnt) + " when the Yo rolls.";
                        return;
                    }
                } else {
                    cAndETtlPymnt = cSidePymnt - amtTwo;
                    losingDiff = Math.abs(cAndETtlPymnt);
                    if (cAndETtlPymnt < 0) {
                        $("c_and_e_calc_result").innerHTML = "Your $" + numCommas(amt) + " Crap won $" + cSidePymnt +  ", which is not enough to cover your $" + numCommas(amtTwo) + " Yo that lost. You need to throw in $" + losingDiff + " to keep this bet up to win again, or take the Yo down and collect your winnings." ;
                        return;
                    } else if (cAndETtlPymnt == 0) {
                        $("c_and_e_calc_result").innerHTML = "Your bet is a push. It is up to win again.";
                        return;
                    } else {
                        $("c_and_e_calc_result").innerHTML = "A $" + numCommas(amt) + "C - $" + numCommas(amtTwo) +  "E wins $" + numCommas(cAndETtlPymnt) + " when the Crap rolls.";
                    }
                }
                break;
            default: return;
        }
    },
    anySeven: function (amt: number) {
        if(validateAmount(amt, 1, 7500,
            "You need to bet at least $1.",
            "Table max on the Any Seven is $7,500.",
            "any_seven_calc_result"
        )) {
            anySevenPymnt = amt * 4;
            $("any_seven_calc_result").innerHTML = "A $" + numCommas(amt) + " Any Seven wins $" + numCommas(anySevenPymnt) + ".";
        }
    },
    anyCraps: function (amt: number) {
        if(validateAmount(amt, 1, 5000,
            "You need to bet at least $1.",
            "Table max on the Any Craps is $5,000.",
            "any_craps_calc_result"
        )) {
            anyCrapsPymnt = amt * 7;
            $("any_craps_calc_result").innerHTML = "A $" + numCommas(amt) + " Any Craps wins $" + numCommas(anyCrapsPymnt) + ".";
        }
    },
    theField: function (betCode: number, amt: number) {
        switch (betCode) {
            case 11:
                if(validateAmount(amt, 5, 15000,
                    "Minimum in the Field is $5.",
                    "Max bet in the Field is $15,000.",
                    "single_field_calc_result"
                )) {
                    singleFieldPymnt = amt;
                    $("single_field_calc_result").innerHTML = "$" + numCommas(amt) + " in the Field pays $" + numCommas(singleFieldPymnt) + " when a 3, 4, 9, 10, or 11 rolls.";
                }
                break;
            case 12:
                if(validateAmount(amt, 5, 15000,
                    "Minimum in the Field is $5.",
                    "Max bet in the Field is $15,000.",
                    "double_field_calc_result"
                )) {
                    doubleFieldPymnt = amt * 2;
                    $("double_field_calc_result").innerHTML = "$" + numCommas(amt) + " in the Field pays $" + numCommas(doubleFieldPymnt) + " when the 2 or 12 is rolled.";
                }
                break;
            default: return;
        }
    },
    multiBet: function (betCode: number, amt: number) {
        switch (betCode) {
            case 18:
                if(validateAmount(amt, 1, 1000,
                    "Minimum bet on the High - Low is $1 each.",
                    "Max bet on the High - Low is $1,000 each.",
                    "high_low_calc_result"
                )) {
                    eachHighLowPymnt = amt * 30 - amt;
                    $("high_low_calc_result").innerHTML = "$" + numCommas(amt) + " each High - Low costs a total of $" + numCommas(amt * 2) + " and pays $" + numCommas(eachHighLowPymnt) + " when either one rolls.";
                }
                break;
            case 19:
                if(validateAmount(amt, 1, 1000,
                    "Minimum bet on the Eleven - Twelve is $1 each.",
                    "Max bet on the Eleven - Twelve is $1,000 each.",
                    "eleven_twelve_calc_result"
                )) {
                    if (theCRoll == 0) {
                        $("eleven_twelve_calc_result").innerHTML = "You need to pick what rolled.";
                    } else {
                        eachElevenPymnt = amt * 15 - amt;
                        eachTwelvePymnt = amt * 30 - amt;
                        theCRoll == 11 ? $("eleven_twelve_calc_result").innerHTML = "$" + numCommas(amt) + " each Eleven - Twelve costs a total of $" + numCommas(amt * 2) + " and pays $" + numCommas(eachElevenPymnt) + " when the Yo rolls." : $("eleven_twelve_calc_result").innerHTML = "$" + numCommas(amt) + " each Eleven - Twelve costs a total of $" + numCommas(amt * 2) + " and pays $" + numCommas(eachTwelvePymnt) + " when the Twelve rolls.";
                    }
                }
                break;
            case 20:
                if(validateAmount(amt, 1, 1000,
                    "Minimum bet on the Aces - Ace Deuce is $1 each.",
                    "Max bet on the Aces - Ace Deuce is $1,000 each.",
                    "aces_ace_deuce_calc_result"
                )) {
                    if (theDRoll == 0) {
                        $("aces_ace_deuce_calc_result").innerHTML = "You need to pick what rolled.";
                    } else {
                        eachAcesPymnt = amt * 30 - amt;
                        eachAceDeucePymnt = amt * 15 - amt;
                        theDRoll == 2 ? $("aces_ace_deuce_calc_result").innerHTML = "$" + numCommas(amt) + " each Aces - Ace Deuce costs a total of $" + numCommas(amt * 2) + " and pays $" + numCommas(eachAcesPymnt) + " when the Aces roll." : $("aces_ace_deuce_calc_result").innerHTML = "$" + numCommas(amt) + " each Aces - Ace Deuce costs a total of $" + numCommas(amt * 2) + " and pays $" + numCommas(eachAceDeucePymnt) + " when the Ace Deuce rolls.";
                    }
                }
                break;
            case 21:
                if(validateAmount(amt, 1, 2000,
                    "Minimum bet on the Seven - Eleven is $1 each.",
                    "Max bet on the Seven - Eleven is $2,000 each.",
                    "seven_eleven_calc_result"
                )) {
                    if (theERoll == 0) {
                        $("seven_eleven_calc_result").innerHTML = "You need to pick what rolled.";
                    } else {
                        eachSevenPymnt = amt * 4 - amt;
                        eachElevenPymnt = amt * 15 - amt;
                        theERoll == 11 ? $("seven_eleven_calc_result").innerHTML = "$" + numCommas(amt) + " each Seven - Eleven costs a total of $" + numCommas(amt * 2) + " and pays $" + numCommas(eachElevenPymnt) + " when the Yo rolls." : $("seven_eleven_calc_result").innerHTML = "$" + numCommas(amt) + " each Seven - Eleven costs a total of $" + numCommas(amt * 2) + " and pays $" + numCommas(eachSevenPymnt) + " when the Seven rolls.";
                    }
                }
                break;
            case 22:
                if(validateAmount(amt, 1, 1000,
                    "Minimum bet on the High - Low is $1 each.",
                    "Max bet on the High - Low is $1,000 each.",
                    "high_low_yo_calc_result"
                )) {
                    if (theFRoll == 0) {
                        $("high_low_yo_calc_result").innerHTML = "You need to pick what rolled.";
                    } else {
                        eachHlyHighPymnt = amt * 30 - (amt * 2);
                        eachHlyLowPymnt = amt * 15 - (amt * 2);
                        switch (theFRoll) {
                            case 2:
                                $("high_low_yo_calc_result").innerHTML = "$" + numCommas(amt) + " each High - Low - Yo costs a total of $" + numCommas(amt * 3) + " and pays $" + numCommas(eachHlyHighPymnt) + " when the Aces roll."
                                break;
                            case 11: 
                                $("high_low_yo_calc_result").innerHTML = "$" + numCommas(amt) + " each High - Low - Yo costs a total of $" + numCommas(amt * 3) + " and pays $" + numCommas(eachHlyLowPymnt) + " when the Yo rolls.";
                                break;
                            case 12: 
                                $("high_low_yo_calc_result").innerHTML = "$" + numCommas(amt) + " each High - Low - Yo costs a total of $" + numCommas(amt * 3) + " and pays $" + numCommas(eachHlyHighPymnt) + " when the Twelve rolls."
                                break;
                            default: return;
                    }
                    }
                }
                break;
            case 23:
                if(validateAmount(amt, 1, 1000,
                    "Minimum bet on the Three Way Crap is $1 each.",
                    "Max bet on the Three Way Crap is $1,000 each.",
                    "three_way_crap_calc_result"
                )) {
                    if (theGRoll == 0) {
                        $("three_way_crap_calc_result").innerHTML = "You need to pick what rolled.";
                    } else {
                        eachTwcHighPymnt = amt * 30 - (amt * 2);
                        eachTwcLowPymnt = amt * 15 - (amt * 2);
                        switch (theGRoll) {
                            case 2:
                                $("three_way_crap_calc_result").innerHTML = "$" + numCommas(amt) + " each Three Way Crap costs a total of $" + numCommas(amt * 3) + " and pays $" + numCommas(eachTwcHighPymnt) + " when the Aces roll."
                                break;
                            case 3: 
                                $("three_way_crap_calc_result").innerHTML = "$" + numCommas(amt) + " each Three Way Crap costs a total of $" + numCommas(amt * 3) + " and pays $" + numCommas(eachTwcLowPymnt) + " when the Ace Deuce rolls.";
                                break;
                            case 12: 
                                $("three_way_crap_calc_result").innerHTML = "$" + numCommas(amt) + " each Three Way Crap costs a total of $" + numCommas(amt * 3) + " and pays $" + numCommas(eachTwcHighPymnt) + " when the Twelve rolls."
                                break;
                            default: return;
                        }
                    }
                    
                }
                break;
            default: return;
        }
    },
    horn: function (amt: number) {
        if (validateAmount(amt, 4, 4000,
            "Minimum bet on the Horn is $4.",
            "Max bet on the Horn is $4,000.",
            "horn_calc_result"
            )) {
                if (theHRoll == 0) {
                    $("horn_calc_result").innerHTML = "You need to pick what rolled.";
                } else if (isImproper) {
                    $("horn_calc_result").innerHTML = "You must make Horn bets in multiples of $4.";
                } else {
                    lowSideHornPymnt = amt * 3;
                    highSideHornPymnt = amt / 4 * 27;
                    if (theHRoll == 3 || theHRoll == 11) {
                        $("horn_calc_result").innerHTML = "A $" + numCommas(amt) + " Horn pays $" + numCommas(lowSideHornPymnt) + " when either low side rolls.";
                    } else {
                        $("horn_calc_result").innerHTML = "A $" + numCommas(amt) + " Horn pays $" + numCommas(highSideHornPymnt) + " when either high side rolls.";
                    }
                }
            }
    },
    hornHigh: function (betCode: number, amt: number) {
        isImproper = amt % 5 !== 0;
        switch (betCode) {
            case 25:
                if (validateAmount(amt, 5, 2500,
                    "Minimum bet on the Horn High Aces is $5.",
                    "Max bet on the Horn High Aces is $2,500.",
                    "horn_high_aces_calc_result"
                )) {
                    if (theIRoll == 0) {
                        $("horn_high_aces_calc_result").innerHTML = "You need to pick what rolled.";
                    } else if (isImproper) {
                        $("horn_high_aces_calc_result").innerHTML = "You must make Horn High Aces bets in multiples of $5.";
                    } else {
                        if (theIRoll == 2) {
                            hornHighAcesPymnt = amt / 5 * 57;
                            $("horn_high_aces_calc_result").innerHTML = "A $" + numCommas(amt) + " Horn High Aces pays $" + numCommas(hornHighAcesPymnt) + " when the Aces Roll.";
                        } else if (theIRoll == 12) {
                            hornHighAcesPymnt = amt / 5 * 26;
                            $("horn_high_aces_calc_result").innerHTML = "A $" + numCommas(amt) + " Horn High Aces pays $" + numCommas(hornHighAcesPymnt) + " when the opposite high side, 12, rolls.";
                        } else if (theIRoll == 3 || theIRoll == 11) {
                            hornHighAcesPymnt = amt / 5 * 11;
                            $("horn_high_aces_calc_result").innerHTML = "A $" + numCommas(amt) + " Horn High Aces pays $" + numCommas(hornHighAcesPymnt) + " when the low side, 3 or 11, rolls.";
                        }
                    }
                }
                break;
            case 26:
                if (validateAmount(amt, 5, 5000,
                    "Minimum bet on the Horn High Ace Deuce is $5.",
                    "Max bet on the Horn High Ace Deuce is $5,000.",
                    "horn_high_ace_deuce_calc_result"
                )) {
                    if (theJRoll == 0) {
                        $("horn_high_ace_deuce_calc_result").innerHTML = "You need to pick what rolled.";
                    } else if (isImproper) {
                        $("horn_high_ace_deuce_calc_result").innerHTML = "You must make Horn High bets in multiples of $5.";
                    } else {
                        if (theJRoll == 3) {
                            hornHighAceDeucePymnt = amt / 5 * 27;
                            $("horn_high_ace_deuce_calc_result").innerHTML = "A $" + numCommas(amt) + " Horn High Ace Deuce pays $" + numCommas(hornHighAceDeucePymnt) + " when the Ace Deuce Rolls.";
                        } else if (theJRoll == 11) {
                            hornHighAceDeucePymnt = amt / 5 * 11;
                            $("horn_high_ace_deuce_calc_result").innerHTML = "A $" + numCommas(amt) + " Horn High Ace Deuce pays $" + numCommas(hornHighAceDeucePymnt) + " when the opposite low side, 11, rolls.";
                        } else {
                            hornHighAceDeucePymnt = amt / 5 * 26;
                            $("horn_high_ace_deuce_calc_result").innerHTML = "A $" + numCommas(amt) + " Horn High Ace Deuce pays $" + numCommas(hornHighAceDeucePymnt) + " when the high side, 2 or 12, rolls.";
                        }
                    }
                }
                break;
            case 27:
                if (validateAmount(amt, 5, 5000,
                    "Minimum bet on the Horn High Yo is $5.",
                    "Max bet on the Horn High Yo is $5,000.",
                    "horn_high_yo_calc_result"
                )) {
                    if (theKRoll == 0) {
                        $("horn_high_yo_calc_result").innerHTML = "You need to pick what rolled.";
                    } else if (isImproper) {
                        $("horn_high_yo_calc_result").innerHTML = "You must make Horn High bets in multiples of $5.";
                    } else {
                        if (theKRoll == 11) {
                            hornHighYoPymnt = amt / 5 * 27;
                            $("horn_high_yo_calc_result").innerHTML = "A $" + numCommas(amt) + " Horn High Yo pays $" + numCommas(hornHighYoPymnt) + " when the Yo Rolls.";
                        } else if (theKRoll == 3) {
                            hornHighYoPymnt = amt / 5 * 11;
                            $("horn_high_yo_calc_result").innerHTML = "A $" + numCommas(amt) + " Horn High Yo pays $" + numCommas(hornHighYoPymnt) + " when the opposite low side, 3, rolls.";
                        } else {
                            hornHighYoPymnt = amt / 5 * 26;
                            $("horn_high_yo_calc_result").innerHTML = "A $" + numCommas(amt) + " Horn High Yo pays $" + numCommas(hornHighYoPymnt) + " when the high side, 2 or 12, rolls.";
                        }
                    }
                }
                break;
            case 28:   
                if (validateAmount(amt, 5, 2500,
                    "Minimum bet on the Horn High Twelve is $5.",
                    "Max bet on the Horn High Twelve is $2,500.",
                    "horn_high_twelve_calc_result"
                )) {
                    if (theLRoll == 0) {
                        $("horn_high_twelve_calc_result").innerHTML = "You need to pick what rolled.";
                    } else if (isImproper) {
                        $("horn_high_twelve_calc_result").innerHTML = "You must make Horn High bets in multiples of $5.";
                    } else {
                        if (theLRoll == 12) {
                            hornHighTwelvePymnt = amt / 5 * 57;
                            $("horn_high_twelve_calc_result").innerHTML = "A $" + numCommas(amt) + " Horn High Twelve pays $" + numCommas(hornHighTwelvePymnt) + " when the Twelve Rolls.";
                        } else if (theLRoll == 2) {
                            hornHighTwelvePymnt = amt / 5 * 26;
                            $("horn_high_twelve_calc_result").innerHTML = "A $" + numCommas(amt) + " Horn High Twelve pays $" + numCommas(hornHighTwelvePymnt) + " when the opposite high side, 2, rolls.";
                        } else {
                            hornHighTwelvePymnt = amt / 5 * 11;
                            $("horn_high_twelve_calc_result").innerHTML = "A $" + numCommas(amt) + " Horn High Twelve pays $" + numCommas(hornHighTwelvePymnt) + " when the low side, 3 or 11, rolls.";
                        }
                    }
                }
                break;
            default: return;
        }
    },
    world: function (amt: number) {
        isImproper = amt % 5 !== 0;
        if (validateAmount(amt, 5, 5000,
            "Minimum for the World is $5.",
            "Max bet on the World is $5,000.",
            "world_calc_result"
            )) {
            if (theMRoll == 0) {
                $("world_calc_result").innerHTML = "You need to pick what rolled.";
            } else if (isImproper) {
                $("world_calc_result").innerHTML = "You must make World bets in multiples of $5.";
            } else {
                if (theMRoll == 2 || theMRoll == 12) {
                    highSideWorldPymnt = amt / 5 * 26;
                    $("world_calc_result").innerHTML = "A $" + numCommas(amt) + " World pays $" + numCommas(highSideWorldPymnt) + " when either high side, 2 or 12, rolls.";
                } else if (theMRoll == 3 || theMRoll == 11){
                    lowSideWorldPymnt = amt / 5 * 11;
                    $("world_calc_result").innerHTML = "A $" + numCommas(amt) + " World pays $" + numCommas(lowSideWorldPymnt) + " when either low side, 3 or 11, rolls.";
                } else {
                    $("world_calc_result").innerHTML = "The World is a push, does not win or lose, when the Seven rolls.";
                }
            }
        }
    },
    hopBet: function (betCode: number, amt: number) {
        switch (betCode) {
            case 29:
                if (validateAmount(amt, 1, 1000,
                    "You must bet at least $1.",
                    "Max bet on any of the Hopping Hardways is $1,000 each.",
                    "high_side_hop_calc_result"
                )) {
                    highSideHopPymnt = amt * 30;
                    $("high_side_hop_calc_result").innerHTML = "A $" + numCommas(amt) + " bet on any of the Hopping Hardways wins $" + numCommas(highSideHopPymnt) + ".";
                }
                break;
            case 30:
                if (validateAmount(amt, 1, 2000,
                    "You must bet at least $1.",
                    "Max bet on any of the Hopping Hardways is $2,000 each.",
                    "low_side_hop_calc_result"
                )) {
                    lowSideHopPymnt = amt * 15;
                    $("low_side_hop_calc_result").innerHTML = "A $" + numCommas(amt) + " bet on a non-pair Hop wins $" + numCommas(lowSideHopPymnt) + ".";
                }
                break;
            case 31:
                if (validateAmount(amt, 1, 1000,
                    "You must bet at least $1 on each Hop.",
                    "Max bet on the Hop 4's is $1,000 each.",
                    "hop_fours_calc_result"
                )) {
                    if (theNRoll == 0) {
                        $("hop_fours_calc_result").innerHTML = "You need to pick what rolled.";
                    } else {
                        if (theNRoll == 31) {
                            hopFoursPymnt = amt * 15 - amt;
                            $("hop_fours_calc_result").innerHTML = "$" + numCommas(amt) + " each Hop 4's costs a total of $" + numCommas(amt * 2) + " and wins $" + numCommas(hopFoursPymnt) + " then the Easy 4 rolls.";
                        } else {
                            hopFoursPymnt = amt * 30 - amt;
                            $("hop_fours_calc_result").innerHTML = "$" + numCommas(amt) + " each Hop 4's  costs a total of $" + numCommas(amt * 2) + " and wins $" + numCommas(hopFoursPymnt) + " then the Hard 4 rolls.";
                        }
                    }
                }
                break;
            case 32:
                if (validateAmount(amt, 1, 2000,
                    "You must bet at least $1 on each Hop.",
                    "Max bet on the Hop 5's is $2,000 each.",
                    "hop_fives_calc_result"
                )) {
                    if (theORoll == 0) {
                        $("hop_fives_calc_result").innerHTML = "You need to pick what rolled.";
                    } else {
                            hopFivesPymnt = amt * 2 *7;
                            $("hop_fives_calc_result").innerHTML = "$" + numCommas(amt) + " each Hop 5's costs a total of $" + numCommas(amt * 2) + " and wins $" + numCommas(hopFivesPymnt) + " when either Hop hits.";
                    }
                }
                break;
            case 33:
                if (validateAmount(amt, 1, 2000,
                    "You must bet at least $1 on each Hop.",
                    "Max bet on the Hop 9's is $2,000 each.",
                    "hop_nines_calc_result"
                )) {
                    if (thePRoll == 0) {
                        $("hop_nines_calc_result").innerHTML = "You need to pick what rolled.";
                    } else {
                            hopNinesPymnt = amt * 15 - amt;
                            $("hop_nines_calc_result").innerHTML = "$" + numCommas(amt) + " each Hop 9's costs a total of $" + numCommas(amt * 2) + " and wins $" + numCommas(hopNinesPymnt) + " when either Hop hits.";
                    }
                }
                break;
            case 34:
                if (validateAmount(amt, 1, 1000,
                    "You must bet at least $1 on each Hop.",
                    "Max bet on the Hop 10's is $1,000 each.",
                    "hop_tens_calc_result"
                )) {
                    if (theQRoll == 0) {
                        $("hop_tens_calc_result").innerHTML = "You need to pick what rolled.";
                    } else if (theQRoll == 64) {
                        hopTensPymnt = amt * 15 - amt;
                        $("hop_tens_calc_result").innerHTML = "$" + numCommas(amt) + " each Hop 10's costs a total of $" + numCommas(amt * 2) + " and wins $" + numCommas(hopTensPymnt) + " then the Easy 10 rolls.";
                    } else {
                        hopTensPymnt = amt * 30 - amt;
                        $("hop_tens_calc_result").innerHTML = "$" + numCommas(amt) + " each Hop 10's costs a total of $" + numCommas(amt * 2) + " and wins $" + numCommas(hopTensPymnt) + " then the Hard 10 rolls.";
                    }
                }
                break;
            case 35:
                if (validateAmount(amt, 1, 1000,
                    "You must bet at least $1 on each Hop.",
                    "Max bet on the Hop 6's is $1,000 each.",
                    "hop_sixes_calc_result"
                )) {
                    if (theRRoll == 0) {
                        $("hop_sixes_calc_result").innerHTML = "You need to pick what rolled.";
                    } else if (theRRoll == 33) {
                        hopSixesPymnt = amt * 30 - (amt * 2);
                        $("hop_sixes_calc_result").innerHTML = "$" + numCommas(amt) + " each Hop 6's costs a total of $" + numCommas(amt * 3) + " and win $" + numCommas(hopSixesPymnt) + " when the Hard 6 rolls.";
                    }  else {
                        hopSixesPymnt = amt * 15 - (amt * 2);
                        $("hop_sixes_calc_result").innerHTML = "$" + numCommas(amt) + " each Hop 6's costs a total of $" + numCommas(amt * 3) + " and win $" + numCommas(hopSixesPymnt) + " when either Easy 6 rolls.";
                    }
                }
                break;
            case 36: 
                if (validateAmount(amt, 1, 2000,
                    "You must bet at least $1 on each Hop.",
                    "Max bet on the Hop 7's is $2,000 each.",
                    "hop_sevens_calc_result"
                )) {
                    if (theSRoll == 0) {
                        $("hop_sevens_calc_result").innerHTML = "You need to pick what rolled.";
                    } else {
                        hopSevensPymnt = amt * 15 - (amt * 2);
                        $("hop_sevens_calc_result").innerHTML = "$" + numCommas(amt) + " each Hop 7's costs a total of $" + numCommas(amt * 3) + " and wins $" + numCommas(hopSevensPymnt) + " when any 7 rolls.";
                    }
                }
                break;
            case 37:
                if (validateAmount(amt, 1, 1000,
                    "You must bet at least $1 on each Hop.",
                    "Max bet on the Hop 8's is $1,000 each.",
                    "hop_eights_calc_result"
                )) {
                    if (theTRoll == 0) {
                        $("hop_eights_calc_result").innerHTML = "You need to pick what rolled.";
                    } else if (theTRoll == 44) {
                        hopEightsPymnt = amt * 30 - (amt * 2);
                        $("hop_eights_calc_result").innerHTML = "$" + numCommas(amt) + " each Hop 8's costs a total of $" + numCommas(amt * 3) + " and wins $" + numCommas(hopEightsPymnt) + " when the Hard 8 rolls.";
                    } else {
                        hopEightsPymnt = amt * 15 - (amt * 2);
                        $("hop_eights_calc_result").innerHTML = "$" + numCommas(amt) + " each Hop 8's costs a total of $" + numCommas(amt * 3) + " and wins $" + numCommas(hopEightsPymnt) + " when either Easy 8 rolls.";
                    }
                }
                break;
            case 38:
                if (validateAmount(amt, 1, 1000,
                    "You must bet at least $1 on each Hop.",
                    "Max bet on the Hop Hardways is $1,000 each.",
                    "hop_hardways_calc_result"
                )) {
                        hopHardwaysPymnt = amt * 30 - (amt * 3);
                        $("hop_hardways_calc_result").innerHTML = "$" + numCommas(amt) + " each Hopping Hardways costs a total of $" + numCommas(amt * 4) + " and wins $" + numCommas(hopHardwaysPymnt) + " when any of the Hardways roll."; 
                }
                break;
            default: return;
        }
    },
    comeBet: function (amt: number) {
        if (validateAmount(amt, 5, 5000,
            "Minimum on the Come is $5.",
            "Table max on the Come is $5,000.",
            "come_bet_calc_result"
            )) {
            comeBetPymnt = amt;
            $("come_bet_calc_result").innerHTML = "A $" + numCommas(amt) + " Come bet wins $" + numCommas(comeBetPymnt) + ".";
        }
    },
    comeBetOdds: function (betCode: number, amt: number) {
        switch (betCode) {
            case 68:
                if (validateAmount(amt, 5, 25000,
                    "Minimum Come Bet Odds on the 6 & 8 are $5.",
                    "Max Come Bet Odds on the 6 & 8 are $25,000.",
                    "six_eight_come_bet_odds_calc_result"
                )) {
                    totalUnits = Math.floor(amt / 5);
                    correctBetPymnt = totalUnits * 6;
                    improperAmount = amt % 5;
                    sixEightComeBetPymnt = correctBetPymnt + improperAmount;
                    isImproper = improperAmount !== 0;
                    if (isImproper) {
                        $("six_eight_come_bet_odds_calc_result").innerHTML = "Come Bet Odds on the 6 & 8 should be made in multiples of 5. Anything above a multiple of 5 will get paid even money. This bet will pay $" + numCommas(sixEightComeBetPymnt) + ".";
                    } else {
                        $("six_eight_come_bet_odds_calc_result").innerHTML = "$" + numCommas(amt) + " Come Bet Odds on the 6 or 8 wins $" + numCommas(sixEightComeBetPymnt) + ".";
                    }
                }
                break;
            case 59:
                if (validateAmount(amt, 2, 20000,
                    "Minimum Come Bet Odds on the 5 & 9 are $2.",
                    "Max Come Bet Odds on the 5 & 9 are $20,000.",
                    "five_nine_come_bet_odds_calc_result"
                )) {
                    fiveNineComeBetPymnt = Math.floor((amt / 2) * 3);
                    isImproper = amt % 2 == 1;
                    if (isImproper) {
                        $("five_nine_come_bet_odds_calc_result").innerHTML = "Come Bet Odds on the 5 & 9 should be even. You lose \$0.50 when you bet uneven Come Bet Odds. This bet should pay $" + numCommas(fiveNineComeBetPymnt) + ".50 but instead pays $" + numCommas(fiveNineComeBetPymnt) + ".";
                    } else {
                        $("five_nine_come_bet_odds_calc_result").innerHTML = "$" + numCommas(amt) + " Come Bet Odds on the 5 or 9 wins $" + numCommas(fiveNineComeBetPymnt) + ".";
                    }
                }
                break;
            case 410:
                if (validateAmount(amt, 1, 15000,
                    "Minimum Come Bet Odds on the 4 & 10 are $1.",
                    "Max Come Bet Odds on the 4 & 10 are $15,000.",
                    "four_ten_come_bet_odds_calc_result"
                )) {
                    fourTenComeBetPymnt = amt * 2;
                    $("four_ten_come_bet_odds_calc_result").innerHTML = "$" + numCommas(amt) + " Come Bet Odds on the 4 or 10 wins $" + numCommas(fourTenComeBetPymnt) + ".";
                }
                break;
                default: return;
        }
    },
    dontPass: function (amt: number) {
        if (validateAmount(amt, 5, 5000,
            "Minimum on the Don't Pass is $5.",
            "Table max on the Don't Pass is $5,000.",
            "dont_pass_calc_result"
        )) {
            dontPassPymnt = amt;
            $("dont_pass_calc_result").innerHTML = "A $" + numCommas(amt) + " Don't Pass bet wins $" + numCommas(dontPassPymnt) + ".";
        }
    },
    dontPassLayOdds: function (betCode: number, amt: number) {
        switch (betCode) {
            case 68:
                if (validateAmount(amt, 6, 36000,
                    "Minimum Lay Odds on the 6 & 8 are $6.",
                    "Max Lay Odds on the 6 & 8 are $36,000.",
                    "six_eight_lay_odds_calc_result"
                )) {
                    sixEightLayOddsPymnt = amt / 6 * 5;
                    isImproper = amt % 6 !== 0;
                    isImproper ? $("six_eight_lay_odds_calc_result").innerHTML = "Lay Odds on the 6 & 8 must be made in multiples of 6." : $("six_eight_lay_odds_calc_result").innerHTML = "$" + numCommas(amt) + " Lay Odds on the 6 or 8 wins $" + numCommas(sixEightLayOddsPymnt) + ".";
                }
                break;
            case 59:
                if (validateAmount(amt, 3, 45000,
                    "Minimum Lay Odds on the 5 & 9 are $3.",
                    "Max Lay Odds on the 5 & 9 are $45,000.",
                    "five_nine_lay_odds_calc_result"
                )) {
                    fiveNineLayOddsPymnt = amt / 3 * 2;
                    isImproper = amt % 3 !== 0;
                    isImproper ? $("five_nine_lay_odds_calc_result").innerHTML = "Lay Odds on the 5 & 9 need to be a multiple of 3." : $("five_nine_lay_odds_calc_result").innerHTML = "$" + numCommas(amt) + " Lay Odds on the 5 or 9 wins $" + numCommas(fiveNineLayOddsPymnt) + ".";
                }
                break;
            case 410:
                if (validateAmount(amt, 2, 60000,
                    "Minimum Lay Odds on the 4 & 10 are $2.",
                    "Max Lay Odds on the 4 & 10 are $60,000.",
                    "four_ten_lay_odds_calc_result"
                )) {
                    fourTenLayOddsPymnt = amt / 2;
                    isImproper = amt % 2 !== 0;
                    isImproper ? $("four_ten_lay_odds_calc_result").innerHTML = "Lay Odds on the 4 & 10 need to be a multiple of 2." : $("four_ten_lay_odds_calc_result").innerHTML = "$" + numCommas(amt) + " Lay Odds on the 4 or 10 wins $" + numCommas(fourTenLayOddsPymnt) + ".";
                }
                break;
            case 66:
                if (validateAmount(amt, 5, 5000,
                    "Minimum on the Don't Pass is $5.",
                    "Table max on the Don't Pass is $5,000.",
                    "max_lay_odds_calc_result"
                )) {
                    maxLayOdds = amt * 6;
                    $("max_lay_odds_calc_result").innerHTML = "The max Lay Odds you can add to your $" + numCommas(amt) + " Don't Pass bet is  $" + numCommas(maxLayOdds) + ".";
                }
            default: return;
        }
    },
    dontCome: function (amt: number) {
        if (validateAmount(amt, 5, 5000,
            "Minimum on the Don't Come is $5.",
            "Table max on the Don't Come is $5,000.",
            "dont_come_calc_result"
        )) {
            dontComePymnt = amt;
            $("dont_come_calc_result").innerHTML = "A $" + amt + " Don't Come bet wins $" + dontComePymnt + ".";
        }
    },
    dontComeLayOdds: function (betCode: number, amt: number) {
        switch (betCode) {
            case 68:
                if (validateAmount(amt, 6, 36000,
                    "Minimum Don't Come Lay Odds on the 6 & 8 are $6.",
                    "Max Don't Come Lay Odds on the 6 & 8 are $36,000.",
                    "six_eight_dont_come_lay_odds_calc_result"
                )) {
                    dcLayOddsSixEightPymnt = amt / 6 * 5;
                    isImproper = amt % 6 !== 0;
                    isImproper ? $("six_eight_dont_come_lay_odds_calc_result").innerHTML = "Don't Come Lay Odds on the 6 & 8 must be made in multiples of 6." : $("six_eight_dont_come_lay_odds_calc_result").innerHTML = "$" + numCommas(amt) + " Don't Come Lay Odds on the 6 or 8 wins $" + numCommas(dcLayOddsSixEightPymnt) + ".";
                }
                break;
            case 59:
                if (validateAmount(amt, 3, 45000,
                    "Minimum Don't Come Lay Odds on the 5 & 9 are $3.",
                    "Max Don't Come Lay Odds on the 5 & 9 are $45,000.",
                    "five_nine_dont_come_lay_odds_calc_result"
                )) {
                    dcLayOddsFiveNinePymnt = amt / 3 * 2;
                    isImproper = amt % 3 !== 0;
                    isImproper ? $("five_nine_dont_come_lay_odds_calc_result").innerHTML = "Don't Come Lay Odds on the 5 & 9 need to be a multiple of 3." : $("five_nine_dont_come_lay_odds_calc_result").innerHTML = "$" + numCommas(amt) + " Don't Come Lay Odds on the 5 or 9 wins $" + numCommas(dcLayOddsFiveNinePymnt) + ".";
                }
                break;
            case 410:
                if (validateAmount(amt, 2, 60000,
                    "Minimum Don't Come Lay Odds on the 4 & 10 are $2.",
                    "Max Don't Come Lay Odds on the 4 & 10 are $60,000.",
                    "four_ten_dont_come_lay_odds_calc_result"
                )) {
                    dcLayOddsFourTenPymnt = amt / 2;
                    isImproper = amt % 2 !== 0;
                    isImproper ? $("four_ten_dont_come_lay_odds_calc_result").innerHTML = "Don't Come Lay Odds on the 4 & 10 need to be a multiple of 2." : $("four_ten_dont_come_lay_odds_calc_result").innerHTML = "$" + numCommas(amt) + " Don't Come Lay Odds on the 4 or 10 wins $" + numCommas(dcLayOddsFourTenPymnt) + ".";
                }
                break;
                case 66:
                    if (validateAmount(amt, 5, 5000,
                        "Minimum on the Don't Come is $5.",
                        "Table max on the Don't Come is $5,000.",
                        "max_dont_come_lay_odds_calc_result"
                    )) {
                        maxDcLayOdds = amt * 6;
                        $("max_dont_come_lay_odds_calc_result").innerHTML = "The max Don't Come Lay Odds you can add to your $" + numCommas(amt) + " Don't Come bet is  $" + numCommas(maxDcLayOdds) + ".";
                    }
            default: return;
        }
    },
    overlay: function (betCode: number, amt: number) {
        switch (betCode) {
            case 68:
                if (validateAmount(amt, 24, 36000,
                    "Minimum Overlay on the 6 & 8 is $24 to win $20.",
                    "Max Overlay on the 6 & 8 is $36,000 to win $30,000.",
                    "overlay_six_eight_calc_result"
                )) {
                    totalUnits = amt / 6;
                    overlay_six_eight_pymnt = totalUnits * 5;
                    vig = Math.floor(amt / 24);
                    totalOverlayCost = amt + vig
                    $("overlay_six_eight_calc_result").innerHTML = "A $" + numCommas(amt) + " Overlay against the 6 or 8 costs $" + numCommas(totalOverlayCost) + ". It pays $" + numCommas(overlay_six_eight_pymnt) + " and requres another $" + vig + " if you would like the bet to stay up to win again.";
                }
                break;
            case 59:
                if (validateAmount(amt, 30, 45000,
                    "Minimum Overlay on the 5 & 9 is $30 to win $20.",
                    "Max Overlay on the 5 & 9 is $45,000 to win $30,000.",
                    "overlay_five_nine_calc_result"
                )) {
                    totalUnits = amt / 3;
                    overlay_five_nine_pymnt = totalUnits * 2;
                    vig = Math.floor(amt / 30);
                    totalOverlayCost = amt + vig
                    $("overlay_five_nine_calc_result").innerHTML = "A $" + numCommas(amt) + " Overlay against the 5 or 9 costs $" + numCommas(totalOverlayCost) + ". It pays $" + numCommas(overlay_five_nine_pymnt) + " and requres another $" + vig + " if you would like the bet to stay up to win again.";
                }
                break;
            case 410:
                if (validateAmount(amt, 40, 60000,
                    "Minimum Overlay on the 4 & 10 is $40 to win $20.",
                    "Max Overlay on the 4 & 10 is $60,000 to win $30,000.",
                    "overlay_four_ten_calc_result"
                )) {
                    overlay_four_ten_pymnt = amt / 2;
                    vig = Math.floor(amt / 40);
                    totalOverlayCost = amt + vig
                    $("overlay_four_ten_calc_result").innerHTML = "A $" + numCommas(amt) + " Overlay against the 4 & 10 costs $" + numCommas(totalOverlayCost) + ". It pays $" + numCommas(overlay_four_ten_pymnt) + " and requres another $" + vig + " if you would like the bet to stay up to win again.";
                }
                break;
            default: return;
        }
    }
}

function payPassLineCalc(amt: number) {calculate.passLine(amt);}
function payPassLineOddsCalc(betCode: number, amt: number) {calculate.passLineOdds(betCode, amt);}
function payMaxOddsCalc(amt: number) {calculate.maxOdds(amt);}
function payPlaceCalc(betCode: number, amt: number) {calculate.placeBet(betCode, amt);}
function payBuyCalc(amt: number) {calculate.buyBet(amt);}
function payHardwayCalc(betCode: number, amt: number) {calculate.theHardways(betCode, amt);}
function payStraightUpCalc(betCode: number, amt: number) {calculate.straightUp(betCode, amt);}
function payCAndECalc(betCode: number, amt: number, amtTwo: number) {calculate.cAndE(betCode, amt, amtTwo);}
function payAnySevenCalc(amt: number) {calculate.anySeven(amt);}
function payAnyCrapsCalc(amt: number) {calculate.anyCraps(amt);}
function payTheFieldCalc(betCode: number, amt: number) {calculate.theField(betCode, amt);}
function payMultiBetCalc(betCode: number, amt: number) {calculate.multiBet(betCode, amt);}
function payHornCalc(amt: number) {calculate.horn(amt);}
function payHornHighCalc(betCode: number, amt: number) {calculate.hornHigh(betCode, amt);}
function payWorldCalc(amt: number) {calculate.world(amt);}
function payHopBetCalc(betCode: number, amt: number) {calculate.hopBet(betCode, amt);}
function payComeBetCalc(amt: number) {calculate.comeBet(amt);}
function payComeBetOddsCalc(betCode: number, amt: number) {calculate.comeBetOdds(betCode, amt);}
function payDontPassCalc(amt: number) {calculate.dontPass(amt);}
function payDontPassLayOddsCalc(betCode: number, amt: number) {calculate.dontPassLayOdds(betCode, amt);}
function payDontComeCalc(amt: number) {calculate.dontCome(amt);}
function payDontComeLayOddsCalc(betCode: number, amt: number) {calculate.dontComeLayOdds(betCode, amt);}
function payOverlayCalc(betCode: number, amt: number) {calculate.overlay(betCode, amt);}
window ['activateBtn'] = activateBtn;
window ['payPassLineCalc'] = payPassLineCalc;
window ['payPassLineOddsCalc'] = payPassLineOddsCalc;
window ['payMaxOddsCalc'] = payMaxOddsCalc;
window ['payPlaceCalc'] = payPlaceCalc;
window ['payBuyCalc'] = payBuyCalc;
window ['payHardwayCalc'] = payHardwayCalc;
window ['payStraightUpCalc'] = payStraightUpCalc;
window ['payCAndECalc'] = payCAndECalc;
window ['payAnySevenCalc'] = payAnySevenCalc;
window ['payAnyCrapsCalc'] = payAnyCrapsCalc;
window ['payTheFieldCalc'] = payTheFieldCalc;
window ['payMultiBetCalc'] = payMultiBetCalc;
window ['payHornCalc'] = payHornCalc;
window ['payHornHighCalc'] = payHornHighCalc;
window ['payWorldCalc'] = payWorldCalc;
window ['payHopBetCalc'] = payHopBetCalc;
window ['payComeBetCalc'] = payComeBetCalc;
window ['payDontPassCalc'] = payDontPassCalc;
window ['payDontPassLayOddsCalc'] = payDontPassLayOddsCalc;
window ['payDontComeCalc'] = payDontComeCalc;
window ['payDontComeLayOddsCalc'] = payDontComeLayOddsCalc;
window ['payOverlayCalc'] = payOverlayCalc;
window ['payComeBetOddsCalc'] = payComeBetOddsCalc;

